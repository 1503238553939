/* AboutSection.css */

.about-title {
    font-size: 2.5rem;
    margin-bottom: 1.5rem;
  }
  
  .about-text {
    font-size: .85rem;
    line-height: 1.6;
    white-space: pre-wrap;
  }

  @media (max-width: 768px) {
    .about-text {
      margin-left: -5%;
    }
    .about-title {
      margin-left: -25%;
    }
  }