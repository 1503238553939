.page-header {
    position: relative;
    height: 100vh;
    overflow: hidden;
  }
  
  .header-filter {
    position: relative;
  }
  
  .header-filter::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #1e1e2f; /* Adjust opacity as needed */
  }
  
  .container {
    position: relative;
  }
  
  .squares {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    /* Add any additional styling for your squares */
  }
  
  .content-center {
    max-width: 650px;
    margin: 0 auto;
  }
  
  .brand h1 {
    color: #ffffff; /* Adjust color as needed */
  }
  
  .brand h4 {
    color: #ffffff; /* Adjust color as needed */
  }
  
  .btn-primary {
    /* Add your button styles here */
  }
  
  .fade-in {
    animation: fadeIn 1s ease-in;
  }
  
  @keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }