.btn-wrapper .btn {
  margin: 0 10px;
  color: #555;
  text-decoration: none;
  transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  position: relative;
  transform: translateZ(0);
}

.btn-wrapper .btn:hover {
  color: #000;
  transform: translateY(-3px) scale(1.05);
}

.btn-wrapper .btn-twitter:hover::after,
.btn-wrapper .btn-instagram:hover::after {
  content: attr(title);
  position: absolute;
  background: #000;
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  top: -30px;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
  z-index: 10;
}

.btn-wrapper .btn-twitter:hover::after {
  content: "Coming Soon!";
}

.btn-wrapper .btn-instagram:hover::after {
  content: "Coming Soon!";
}

/* New styles for the "Created with" section */
.created-with-card {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  padding: 15px;
  padding-right: 25px;
  color: #ffffff;
  margin-right: 0;
  width: 100%;
  transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
  transform: translateZ(0);
}

.created-with-card:hover {
  transform: translateZ(5px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
}

.created-with-card h3 {
  color: #ffffff;
  margin-bottom: 10px;
}

.created-with-card a {
  color: #a0a0ff;
  text-decoration: none;
}

.created-with-card a:hover {
  text-decoration: underline;
}

.thank-you {
  color: #a0a0ff;
}

@media (max-width: 768px) {
  .created-with-card {
    margin-top: 10px;
    width: 90%;
    padding: 12px 15px;
    padding-right: 15px;
    margin-right: 0;
    font-size: 0.9em;
  }

  .created-with-card h3 {
    font-size: 1.1em;
    margin-bottom: 8px;
  }

  .claude-info {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .claude-info p {
    margin-bottom: 0;
  }

  .thank-you {
    margin-top: 8px;
    font-size: 0.9em;
  }
}

.copyright {
  font-size: 0.9rem;
  color: #999;
  margin-top: 1rem;
  padding-top: 1rem;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.footer {
  padding: 40px 0;
  position: relative;
  overflow: hidden;
  perspective: 1000px;
}

.footer::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(135deg, rgba(255,255,255,0.03) 0%, transparent 100%);
  transform: translateZ(-1px);
  pointer-events: none;
}

.footer .container {
  padding: 0;
}

/* Ensure consistent spacing in columns */
.footer .col-md-3 {
  padding: 15px 25px;
}

/* Adjust spacing for mobile */
@media (max-width: 768px) {
  .footer .container {
    padding: 0 30px;
  }

  .footer .col-md-3 {
    padding: 15px 20px;
  }

  .created-with-card {
    margin-top: 10px;
    width: 100%;
  }
}

.footer-content-wrapper {
  width: 95%;
  margin: 0 auto;
  max-width: 1200px;
  transform: translateZ(0);
}

@media (max-width: 768px) {
  .footer-content-wrapper {
    width: 90%;
    padding-right: 15px;
  }
}

/* Animated links */
.footer .nav-link {
  position: relative;
  transition: all 0.3s ease;
  padding-left: 0;
}

.footer .nav-link::before {
  content: '→';
  position: absolute;
  left: -20px;
  opacity: 0;
  transition: all 0.3s ease;
}

.footer .nav-link:hover {
  padding-left: 15px;
  color: #fff;
}

.footer .nav-link:hover::before {
  left: 0;
  opacity: 1;
}

/* Animate the logo on hover */
.claude-info img {
  transition: transform 0.4s cubic-bezier(0.68, -0.55, 0.27, 1.55);
}

.claude-info:hover img {
  transform: rotate(360deg) scale(1.2);
}