.blog-card {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 15px;
  padding: 1.5rem;
  height: auto;
  min-height: 200px;
  cursor: pointer;
  transition: all 0.3s ease;
  margin: 1rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.blog-card a {
  color: white;
  text-decoration: none;
}

.blog-card h3 {
  font-size: 1.25rem;
  margin-bottom: 0.75rem;
  color: white;
}

.blog-card p {
  color: rgba(255, 255, 255, 0.7);
  margin-bottom: 1rem;
  font-size: 0.9rem;
  line-height: 1.4;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.read-more {
  color: #e14eca;
  font-weight: 600;
  transition: transform 0.3s ease;
  display: inline-block;
  font-size: 0.9rem;
}

.blog-card:hover .read-more {
  transform: translateX(10px);
}

.row-grid {
  margin-top: -1rem;
  margin-bottom: -1rem;
}

@media (max-width: 768px) {
  .blog-card {
    margin: 0.5rem;
    min-height: 180px;
  }
}

.blog-date {
  color: #888;
  font-size: 0.9em;
  margin-bottom: 10px;
} 