.tool-card {
  background: #ffffff;
  border-radius: 15px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s ease;
}

.tool-card:hover {
  transform: translateY(-5px);
}

.tool-preview {
  width: 100%;
  overflow: hidden;
  border-bottom: 1px solid #eee;
}

.tool-preview img {
  width: 100%;
  height: auto;
  transition: transform 0.3s ease;
}

.tool-preview:hover img {
  transform: scale(1.02);
}

.tool-info {
  padding: 2rem;
  text-align: center;
}

.tool-info h3 {
  margin-bottom: 1rem;
  color: #333;
}

.tool-info p {
  color: #666;
  margin-bottom: 1.5rem;
}

.btn-primary {
  padding: 0.75rem 2rem;
  border-radius: 30px;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 0.5px;
}

@media screen and (max-width: 991px) {
  .section#tools {
    margin-right: 15px;
    margin-left: 15px;
  }

  .tool-card {
    margin: 0;
  }

  .tool-preview img {
    width: 100%;
    height: auto;
  }

  .tool-info {
    padding: 20px;
  }
} 