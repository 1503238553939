body {
    background: linear-gradient(to bottom, #1e1e2f, #1e1e24);
    margin: 0;
    padding: 0;
  }

  html, body {
    overflow-x: hidden;
    width: 100%;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
  }
  
  #root {
    overflow-x: hidden;
  }
  
  .wrapper {
    overflow-x: hidden;
  }
  
  .main {
    padding-top: 100px; /* Adjust this value based on your navbar height */
  }
  
  .page-header {
    min-height: 100vh;
    max-height: 999px;
    padding: 0;
    color: #ffffff;
    position: relative;
    overflow: hidden;
  }
  
  .page-header > .content {
    margin-top: 12%;
    text-align: center;
    margin-bottom: 50px;
  }
  
  .navbar {
    position: fixed;
    width: 100%;
    z-index: 1050;
  }
  
  /* Rest of your existing styles */
  .text-blue {
    color: #3a02d6 !important;
  }
  
  .text-white {
    color: #ffffff !important;
  }

  .floating {
    animation: floating 3s ease-in-out infinite;
  }
  
  @keyframes floating {
    0% { transform: translate(0,  0px); }
    50%  { transform: translate(0, 15px); }
    100%   { transform: translate(0, -0px); }   
  }
  
  .fade-in {
    opacity: 1;
    transform: translateY(20px);
    transition: opacity 0.5s ease-out, transform 0.5s ease-out;
  }
  
  .fade-in.appear {
    opacity: 1;
    transform: translateY(0);
  }
  
  .icon {
    transition: transform 0.3s ease-in-out;
  }
  
  .icon:hover {
    transform: scale(1.1);
  }

  .adjusted-header {
    padding-top: 300px; /* Adjust this value as needed */
  }

  .page-header {
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    background-size: cover;
    background-position: center center;
    min-height: 100vh;
  }
  
  .squares {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
  
  .squares li {
    position: absolute;
    display: block;
    list-style: none;
    width: 20px;
    height: 20px;
    background: rgba(255, 255, 255, 0.1);
    animation: animate 25s linear infinite;
    bottom: -150px;
  }
  
  .squares li:nth-child(1) {
    left: 25%;
    width: 80px;
    height: 80px;
    animation-delay: 0s;
  }
  
  .squares li:nth-child(2) {
    left: 10%;
    width: 20px;
    height: 20px;
    animation-delay: 2s;
    animation-duration: 12s;
  }
  
  .squares li:nth-child(3) {
    left: 70%;
    width: 20px;
    height: 20px;
    animation-delay: 4s;
  }
  
  .squares li:nth-child(4) {
    left: 40%;
    width: 60px;
    height: 60px;
    animation-delay: 0s;
    animation-duration: 18s;
  }
  
  .squares li:nth-child(5) {
    left: 65%;
    width: 20px;
    height: 20px;
    animation-delay: 0s;
  }
  
  .squares li:nth-child(6) {
    left: 75%;
    width: 110px;
    height: 110px;
    animation-delay: 3s;
  }
  
  .squares li:nth-child(7) {
    left: 35%;
    width: 150px;
    height: 150px;
    animation-delay: 7s;
  }
  
  @keyframes animate {
    0% {
      transform: translateY(0) rotate(0deg);
      opacity: 1;
      border-radius: 0;
    }
    100% {
      transform: translateY(-1000px) rotate(720deg);
      opacity: 0;
      border-radius: 50%;
    }
  }

  .widget-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0;
  }

  .app-wrapper {
    position: relative;
    overflow-x: hidden;
    width: 100%;
  }

  h1, h2, h3, h4, h5, h6 {
    user-select: none; /* Prevents text selection and caret from appearing */
  }

  .sunburst {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0;
  }

  @media screen and (max-width: 991px) {
    .section {
      margin-right: 15px;
      margin-left: 15px;
    }
    
    .container {
      padding-right: 0;
      padding-left: 0;
    }
  }