/* Use !important judiciously to override bootstrap styles */
.navbar.navbar-expand-lg.fixed-top {
  height: 8vh;
  min-height: 50px; /* For very small screens */
  padding-top: 0;
  padding-bottom: 0;
  display: flex;
  align-items: center;
  transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1) !important;
  background-color: transparent !important;
  backdrop-filter: blur(0px);
  transform: translateZ(0);
  will-change: background-color, padding, backdrop-filter, height;
}

.navbar.navbar-expand-lg.fixed-top.navbar-scrolled {
  background-image: linear-gradient(to top right, #35d977, #7454f5, #b74ee1);
  backdrop-filter: blur(8px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  transform: translateZ(0) translateY(0);
  height: 7vh;
  min-height: 45px;
}

/* Ensure text is visible on transparent background */
.navbar.navbar-expand-lg.fixed-top .navbar-brand,
.navbar.navbar-expand-lg.fixed-top .nav-link {
  color: #fff !important; /* Dark color for visibility */
}

.navbar.navbar-expand-lg.fixed-top.navbar-scrolled .navbar-brand,
.navbar.navbar-expand-lg.fixed-top.navbar-scrolled .nav-link {
  color: #fff !important; /* Light color for visibility on colored background */
}

/* Additional styles to ensure visibility */
.navbar.navbar-expand-lg.fixed-top .navbar-toggler-icon {
  background-color: #333;
}

.navbar.navbar-expand-lg.fixed-top.navbar-scrolled .navbar-toggler-icon {
  background-color: #fff;
}

/* Adjust these values as needed for your design */
.navbar.navbar-expand-lg.fixed-top {
  padding-top: 20px;
  padding-bottom: 20px;
}

.navbar.navbar-expand-lg.fixed-top.navbar-scrolled {
  padding-top: 10px;
  padding-bottom: 10px;
}
.smooth-edge-span {
  border-radius: 5px; /* This creates smooth edges */
}

/* Overlay for mobile menu */
.navbar-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: transparent;
  z-index: 1029; /* Just below the navbar */
  cursor: pointer;
}

@media screen and (max-width: 991px) {
  .navbar-collapse.show {
    padding: 1rem;
    border-radius: 8px;
    margin-top: 1rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }

  .navbar-collapse.show .navbar-nav {
    margin-top: 1rem;
  }

  .navbar-collapse.show .nav-link {
    padding: 0.75rem 1rem;
    border-radius: 4px;
  }

  .navbar-collapse.show .nav-link:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }

  /* Ensure the menu button stays visible */
  .navbar-toggler {
    z-index: 1031;
    position: relative;
  }

  .navbar.navbar-expand-lg.fixed-top {
    height: 7vh;
    min-height: 45px;
  }
  
  .navbar.navbar-expand-lg.fixed-top.navbar-scrolled {
    height: 6vh;
    min-height: 40px;
  }
}

.navbar-translate {
  display: flex;
  justify-content: center;
  width: 100%;
}

.brand-container {
  display: flex;
  align-items: center;
  background-color: rgba(211, 211, 211, 0.2);
  padding: 6px 14px;
  border-radius: 6px;
  transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
  transform: translateZ(0);
  perspective: 1000px;
  will-change: transform, background-color;
  transform-origin: left center;
}

.brand-container:hover {
  transform: translateZ(10px) scale(1.02);
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

.navbar-scrolled .brand-container {
  background-color: transparent;
  transform: translateZ(0);
}

.brand-container img {
  transition: transform 0.3s ease;
  margin-right: 12px;
}

.brand-container:hover img {
  transform: rotate(5deg);
}

/* Adjust button position since we centered the brand */
.navbar-toggler {
  position: absolute;
  right: 0;
}

/* Ensure proper mobile layout */
@media screen and (max-width: 991px) {
  .navbar-translate {
    justify-content: flex-start;
    align-items: center;  /* Align items vertically */
  }

  .navbar-toggler {
    position: relative;  /* Change from absolute to relative */
    right: 15px;        /* Add some right margin */
    padding: 5px;       /* Reduce padding of toggle button */
  }

  /* Reduce brand container size on mobile */
  .brand-container {
    padding: 4px 12px;
    transform: scale(0.9);  /* Slightly reduce overall size */
  }

  /* Reduce logo size on mobile */
  .brand-container img {
    width: 45px !important;  /* Override inline style */
  }

  /* Adjust text size on mobile */
  .brand-container span {
    font-size: 0.9em;
  }

  /* Make container smaller */
  .container {
    padding-right: 20px;
    padding-left: 20px;
  }
}

.brand-container span {
  white-space: nowrap;
}

/* Adjust toggle button bars size */
.navbar-toggler-bar {
  width: 22px;
  height: 2px;
  margin: 4px 0;
}

/* Enhanced transitions for nav links */
.navbar-expand-lg .nav-link {
  position: relative;
  transition: transform 0.3s, color 0.3s !important;
  transform-style: preserve-3d;
}

.navbar-expand-lg .nav-link:hover {
  transform: translateY(-2px) translateZ(5px);
}

.navbar-expand-lg .nav-link::after {
  content: '';
  position: absolute;
  width: 0;
  height: 2px;
  bottom: 0;
  left: 50%;
  background: #fff;
  transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transform: translateX(-50%);
  opacity: 0;
}

.navbar-expand-lg .nav-link:hover::after {
  width: 80%;
  opacity: 1;
}