/* AIChat.css - Complete file with mobile responsiveness */

/* Base styles */
body, html {
  overflow-x: hidden;
  width: 100%;
  position: relative;
}

.haiguy-chat-container {
  position: fixed !important;
  bottom: 20px !important;
  right: 20px !important;
  width: 350px !important;
  height: 500px !important;
  max-width: calc(100vw - 40px) !important;
  max-height: calc(100vh - 40px) !important;
  z-index: 9999 !important;
  transition: all 0.3s ease-in-out !important;
  display: flex !important;
  flex-direction: column !important;
}

.haiguy-chat-container.haiguy-maximized {
  width: 100% !important;
  height: 100% !important;
  max-width: none !important;
  max-height: none !important;
  top: 0 !important;
  left: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  border-radius: 0 !important;
}

@media (max-width: 768px) {
  .haiguy-chat-container {
    width: 75% !important;
    height: 75% !important;
    max-width: 75% !important;
    max-height: 75% !important;
    right: 0 !important;
    bottom: 0 !important;
  }

  .haiguy-chat-container.haiguy-maximized {
    width: 100% !important;
    height: 100% !important;
    max-width: 100% !important;
    max-height: 100% !important;
  }
}

/* Chat window */
.haiguy-chat-window {
  width: 100% !important;
  height: 100% !important;
  display: flex !important;
  flex-direction: column !important;
  background-color: #1a1a1a !important;
  border-radius: 0.5rem !important;
  overflow: hidden !important;
  border: 1px solid #333 !important;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08) !important;
}

/* Chat header */
.haiguy-chat-header {
  background-color: #2c3e50 !important;
  color: #ffffff !important;
  padding: 0.75rem !important;
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
}

.haiguy-chat-header-left {
  display: flex !important;
  align-items: center !important;
}

.haiguy-chat-header-icon {
  font-size: 1.5rem !important;
  margin-right: 0.5rem !important;
}

.haiguy-chat-header-icon img {
  width: 50% !important;
}

.haiguy-chat-header-title {
  font-size: 1.25rem !important;
  font-weight: bold !important;
  text-align: left !important;
}

.haiguy-chat-header-controls {
  display: flex !important;
  gap: 0.5rem !important;
}

.haiguy-control-button {
  background: none !important;
  border: none !important;
  color: #ffffff !important;
  cursor: pointer !important;
  padding: 0.25rem !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.haiguy-control-button:hover {
  background-color: rgba(255, 255, 255, 0.1) !important;
  border-radius: 0.25rem !important;
}

/* Collapsed button */
.haiguy-collapsed-button {
  position: fixed !important;
  bottom: 20px !important;
  right: 20px !important;
  width: 60px !important;
  height: 60px !important;
  border-radius: 50% !important;
  background-color: #2c3e50 !important;
  color: #ffffff !important;
  font-size: 2rem !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  cursor: pointer !important;
  border: none !important;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2) !important;
  transition: all 0.3s ease-in-out !important;
  z-index: 9999 !important;
}

.haiguy-collapsed-button:hover {
  transform: scale(1.1) !important;
}

.haiguy-collapsed-button img {
  width: 100% !important;
  height: 100% !important;
  object-fit: cover !important;
}

/* Chat messages area */
.haiguy-chat-messages {
  flex: 1 !important;
  overflow-y: auto !important;
  padding: 1rem !important;
  display: flex !important;
  flex-direction: column !important;
  gap: 1rem !important;
  background-color: #2c2c2c !important;
}

/* Message bubbles */
.haiguy-message {
  max-width: 70% !important;
  padding: 0.5rem 1rem !important;
  border-radius: 1rem !important;
}

.haiguy-message-user {
  align-self: flex-end !important;
  background-color: #3498db !important;
  color: #ffffff !important;
}

.haiguy-message-ai {
  align-self: flex-start !important;
  background-color: #34495e !important;
  color: #ffffff !important;
}

/* Typing indicator */
.haiguy-typing-indicator {
  align-self: flex-start !important;
  background-color: #34495e !important;
  color: #ffffff !important;
  padding: 0.5rem 1rem !important;
  border-radius: 1rem !important;
}

/* Input area */
.haiguy-input-area {
  padding: 0.75rem !important;
  background-color: #2c2c2c !important;
  border-top: 1px solid #333 !important;
}

.haiguy-input-container {
  display: flex !important;
  align-items: center !important;
}

.haiguy-input {
  flex: 1 !important;
  padding: 0.5rem !important;
  border-radius: 0.375rem 0 0 0.375rem !important;
  border: 1px solid #4a4a4a !important;
  background-color: #3a3a3a !important;
  color: #ffffff !important;
  outline: none !important;
  min-width: 0 !important;
}

.haiguy-input:focus {
  box-shadow: 0 0 0 2px rgba(59, 130, 246, 0.5) !important;
}

.haiguy-send-button {
  padding: 0.5rem !important;
  background-color: #3498db !important;
  color: #ffffff !important;
  border-radius: 0 0.375rem 0.375rem 0 !important;
  border: none !important;
  cursor: pointer !important;
  transition: background-color 0.2s !important;
  flex-shrink: 0 !important;
}

.haiguy-send-button:hover {
  background-color: #2980b9 !important;
}

/* Scrollbar styles */
.haiguy-chat-messages::-webkit-scrollbar {
  width: 8px !important;
}

.haiguy-chat-messages::-webkit-scrollbar-track {
  background: #1a1a1a !important;
}

.haiguy-chat-messages::-webkit-scrollbar-thumb {
  background-color: #4a4a4a !important;
  border-radius: 4px !important;
}

.haiguy-chat-messages::-webkit-scrollbar-thumb:hover {
  background-color: #5a5a5a !important;
}

/* Mobile styles */
@media (max-width: 768px) {

  .haiguy-collapsed-button {
    width: 50px !important;
    height: 50px !important;
    right: 10px !important;
    bottom: 10px !important;
  }

  .haiguy-chat-header {
    padding: 0.5rem !important;
  }

  .haiguy-chat-header-title {
    font-size: 1rem !important;
  }

  .haiguy-input-area {
    padding: 0.5rem !important;
  }

  .haiguy-input {
    font-size: 14px !important;
  }

  .haiguy-send-button {
    padding: 0.4rem !important;
  }
}

/* Powered by section */
.haiguy-chat-powered-by {
  display: flex !important;
  align-items: center !important;
  justify-content: flex-end !important;
  padding: 0.25rem 0.75rem !important;
  background-color: #2c3e50 !important;
  color: #ffffff !important;
  font-size: 0.75rem !important;
}

.haiguy-chat-powered-by span {
  margin-right: 0.5rem !important;
}

.haiguy-chat-powered-by img {
  width: 8% !important;
  max-height: 20px !important;
  object-fit: contain !important;
}

.haiguy-collapsed-button {
  width: 60px !important;
  height: 60px !important;
  position: fixed !important;
  bottom: 20px !important;
  right: 20px !important;
  z-index: 1000 !important;
  background: transparent !important;
  border: none !important;
  cursor: pointer !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  padding: 0 !important;
}

.haiguy-collapsed-button img {
  width: 100% !important;
  height: 100% !important;
  object-fit: contain !important;
}

/* Media query for smaller screens */
@media (max-width: 768px) {
  .haiguy-collapsed-button {
    width: 50px !important;
    height: 50px !important;
    bottom: 10px !important;
    right: 10px !important;
  }
}

/* Additional media query for very small screens (e.g., iPhone SE) */
@media (max-width: 375px) {
  .haiguy-collapsed-button {
    width: 40px !important;
    height: 40px !important;
  }
}

.haiguy-chat-gpt-logo {
  position: relative;
}

.haiguy-chat-gpt-logo::after {
  content: attr(title);
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  background: #333;
  color: white;
  padding: 5px;
  border-radius: 3px;
  white-space: nowrap;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s, visibility 0.3s;
}

.haiguy-chat-gpt-logo:hover::after,
.haiguy-chat-gpt-logo.show-tooltip::after {
  opacity: 1;
  visibility: visible;
}

.haiguy-message strong {
  font-weight: 600;
  color: #4a90e2;
}

.haiguy-message p {
  margin: 0.5em 0;
}

.haiguy-message p:first-child {
  margin-top: 0;
}

.haiguy-message p:last-child {
  margin-bottom: 0;
}

.haiguy-message-ai strong {
  color: #4a90e2;
}

.haiguy-message-user strong {
  color: #fff;
}
