.hover-card {
    transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
    backface-visibility: hidden;
    transform: translateZ(0);
    will-change: transform, box-shadow;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    transform-style: preserve-3d;
    transform: perspective(1000px) rotateX(0) rotateY(0) scale(1);
  }
  
  .hover-card::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(145deg, rgba(255,255,255,0.1), rgba(255,255,255,0));
    opacity: 0;
    transition: opacity 0.4s ease;
    pointer-events: none;
  }
  
  .hover-card:hover {
    transform: perspective(1000px) rotateX(2deg) rotateY(2deg) translateY(-10px) scale(1.02);
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.15);
  }
  
  .hover-card:hover::after {
    opacity: 1;
  }
  
  .card .icon {
    transition: all 0.3s cubic-bezier(0.68, -0.55, 0.27, 1.55);
    transform: translateZ(0);
    perspective: 1000px;
  }
  
  .hover-card:hover .icon {
    transform: translateZ(20px) scale(1.1);
  }
  
  .fade-in {
    opacity: 0;
    transform: translateY(20px);
    animation: fadeIn 0.8s forwards;
  }
  
  .col-lg-4:nth-child(1) .fade-in { animation-delay: 0.1s; }
  .col-lg-4:nth-child(2) .fade-in { animation-delay: 0.2s; }
  .col-lg-4:nth-child(3) .fade-in { animation-delay: 0.3s; }
  .col-lg-4:nth-child(4) .fade-in { animation-delay: 0.4s; }
  .col-lg-4:nth-child(5) .fade-in { animation-delay: 0.5s; }
  .col-lg-4:nth-child(6) .fade-in { animation-delay: 0.6s; }
  
  @keyframes fadeIn {
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .section-lg {
    position: relative;
    overflow: hidden;
  }
  
  .section-lg::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: radial-gradient(circle, rgba(255,255,255,0.03) 1px, transparent 1px);
    background-size: 25px 25px;
    transform: translateZ(-1px) scale(1.1);
    pointer-events: none;
  }
  
  .section-lg::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(135deg, rgba(255,255,255,0.03) 0%, transparent 50%);
    transform: translateZ(-2px) scale(1.2);
    pointer-events: none;
  }
  
  .modal.show .modal-dialog {
    animation: modalPop 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }
  
  @keyframes modalPop {
    0% { transform: scale(0.8); opacity: 0; }
    100% { transform: scale(1); opacity: 1; }
  }

  .custom-close-button {
    text-decoration: none;
    color: black;
    font-size: 1.5rem;
  }
  
  .custom-close-button:hover {
    color: red;
  }

  @media (max-width: 768px) {
    .row-grid {
      width: 97%;
    }
  }

  /* Improved card content transitions */
  .card-title, .card-text, .card .icon i {
    transition: transform 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }

  .hover-card:hover .card-title {
    transform: translateZ(20px);
  }

  .hover-card:hover .card-text {
    transform: translateZ(15px);
    transition-delay: 0.05s;
  }

  .hover-card:hover .icon i {
    transform: translateZ(30px) scale(1.1);
    transition-delay: 0.1s;
  }
