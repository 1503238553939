/* ContactSection.css */

.contact-section .form-group {
    margin-bottom: 20px; /* Add space between form groups */
  }
  
  .contact-section .form-control {
    width: 100%; /* Ensure full width for inputs */
  }
  
  .contact-section .btn {
    width: auto; /* Adjust button width */
    padding: 10px 20px; /* Add padding to the button */
  }
  
  .contact-section .row {
    margin-bottom: 20px; /* Add space between rows */
  }

#successMessage {
    opacity: 1;
    transition: opacity 1s ease-out;
  }
  
  #successMessage.fade-out {
    opacity: 0;
  }

  .contact-section {
    padding: 4rem 0;
    overflow-x: hidden;
  }
  
  .contact-section .container {
    max-width: 100%;
    padding: 0 15px;
  }
  
  .contact-section .row {
    margin-left: -15px;
    margin-right: -15px;
  }
  
  .contact-section .col-md-6,
  .contact-section .col-md-4 {
    padding: 0 15px;
  }
  
  .contact-section .card {
    margin-bottom: 2rem;
  }
  
  .contact-section .form-group {
    margin-bottom: 1.5rem;
  }
  
  .contact-section .form-control {
    width: 100%;
  }
  
  .contact-section .btn {
    width: auto;
    padding: 0.75rem 1.5rem;
  }
  
  @media (max-width: 768px) {
    .contact-section .col-md-4 {
      margin-left: 0;
    }
    
    .contact-section .info {
      text-align: center;
      margin-top: 2rem;
    }
    
    .contact-section .info .icon {
      margin-bottom: 1rem;
    }
    .card-body {
      width: 70%;
    }
  }
  
  #successMessage {
    opacity: 1;
    transition: opacity 1s ease-out;
  }
  
  #successMessage.fade-out {
    opacity: 0;
  }