/* Client Work Section Layout */
.client-work-section {
  padding: 100px 0;
  background: linear-gradient(135deg, rgba(20, 30, 48, 0.8) 0%, rgba(36, 59, 85, 0.8) 100%);
  position: relative;
  overflow: hidden;
  perspective: 1000px;
  margin-top: 50px; /* Add spacing between sections */
}

/* Background patterns and effects */
.client-work-section::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: 
    linear-gradient(to right, rgba(255,255,255,0.03) 1px, transparent 1px),
    linear-gradient(to bottom, rgba(255,255,255,0.03) 1px, transparent 1px);
  background-size: 30px 30px;
  transform: translateZ(-1px);
  z-index: -1;
}

.client-work-section::after {
  content: '';
  position: absolute;
  top: -50%;
  left: -50%;
  right: -50%;
  bottom: -50%;
  background: radial-gradient(circle at center, rgba(120, 100, 255, 0.15) 0%, transparent 70%);
  transform: translateZ(-2px) rotate(-12deg);
  z-index: -2;
}

/* Section header styles */
.section-header {
  text-align: center;
  margin-bottom: 60px;
  position: relative;
  z-index: 1;
}

.section-title {
  font-size: 2.8rem;
  margin-bottom: 20px;
  color: #fff;
  opacity: 0;
  transform: translateY(30px);
  transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.section-title .highlight {
  position: relative;
  display: inline-block;
}

.section-title .highlight::after {
  content: '';
  position: absolute;
  bottom: -5px;
  left: 0;
  width: 100%;
  height: 3px;
  background: linear-gradient(90deg, #35d977, #7454f5, #b74ee1);
  transform: scaleX(0);
  transform-origin: left;
  transition: transform 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.3s;
}

.section-title.animate-in {
  opacity: 1;
  transform: translateY(0);
}

.section-title.animate-in .highlight::after {
  transform: scaleX(1);
}

.section-subtitle {
  display: none;
}

/* Projects grid layout */
.projects-grid-container {
  width: 100%;
  overflow: visible;
}

.projects-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 25px;
  position: relative;
  z-index: 1;
  margin-bottom: 30px;
}

@media (max-width: 1200px) {
  .projects-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .projects-grid {
    grid-template-columns: 1fr;
  }
  
  .client-work-section {
    padding: 70px 0;
  }
}

/* Project card styles */
.project-card {
  background: rgba(255, 255, 255, 0.05);
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.15);
  transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
  transform: translateZ(0) perspective(1000px);
  cursor: pointer;
  opacity: 0;
  transform: translateY(30px);
  animation: fadeInUp 0.8s forwards ease-out;
  height: 100%;
  min-height: 350px;
  display: flex;
  flex-direction: column;
}

@keyframes fadeInUp {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.project-card.animate-in {
  opacity: 1;
  transform: translateY(0);
}

.project-card:hover {
  transform: translateY(-10px) rotateX(5deg) rotateY(5deg) scale(1.03);
  box-shadow: 0 15px 40px rgba(0, 0, 0, 0.3);
}

.project-card.hovered {
  z-index: 10;
}

.project-image-container {
  position: relative;
  overflow: hidden;
  height: 240px;
}

.project-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.project-card:hover .project-image {
  transform: scale(1.1);
}

.project-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.4s ease;
}

.project-card:hover .project-overlay {
  opacity: 1;
}

.view-site {
  color: #fff;
  font-size: 1.1rem;
  font-weight: 500;
  padding: 10px 20px;
  border-radius: 30px;
  background: linear-gradient(90deg, #35d977, #7454f5);
  transform: translateY(20px);
  opacity: 0;
  transition: all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.1s;
}

.project-card:hover .view-site {
  transform: translateY(0);
  opacity: 1;
}

.project-info {
  padding: 20px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.project-title {
  font-size: 1.5rem;
  color: #fff;
  margin-bottom: 10px;
  position: relative;
  display: inline-block;
}

.project-description {
  color: rgba(255, 255, 255, 0.7);
  margin-bottom: 15px;
  line-height: 1.6;
  flex-grow: 1;
}

.project-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: auto;
}

.project-tag {
  background: rgba(255, 255, 255, 0.08);
  padding: 5px 12px;
  border-radius: 15px;
  font-size: 0.85rem;
  color: rgba(255, 255, 255, 0.9);
  transition: all 0.3s ease;
  cursor: default;
}

.project-tag:hover {
  background: rgba(255, 255, 255, 0.15);
}

/* Coming soon placeholder */
.project-card.coming-soon {
  border: 2px dashed rgba(255, 255, 255, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 350px;
  background: rgba(255, 255, 255, 0.05);
}

.coming-soon .placeholder-content {
  padding: 30px;
  text-align: center;
}

/* House card styles */
.house-card {
  background: linear-gradient(135deg, rgba(53, 217, 119, 0.05) 0%, rgba(116, 84, 245, 0.07) 100%);
  border: none !important;
  position: relative;
  overflow: hidden;
}

.house-container {
  width: 200px;
  height: 150px;
  margin: 0 auto 25px;
  position: relative;
}

.house-svg {
  width: 100%;
  height: 100%;
}

/* Initial state of house elements - invisible or partially built */
.house-svg .ground,
.house-svg .walls,
.house-svg .roof,
.house-svg .door,
.house-svg .doorknob,
.house-svg .window-left,
.house-svg .window-right,
.house-svg .window-frame,
.house-svg .chimney,
.house-svg .smoke1,
.house-svg .smoke2,
.house-svg .smoke3 {
  opacity: 0;
  transform-origin: center bottom;
  transition: all 0.5s ease-out;
}

/* Animation sequence when hovered */
.house-card:hover .ground {
  opacity: 1;
  transition-delay: 0s;
}

.house-card:hover .walls {
  opacity: 1;
  transform: translateY(0);
  transition-delay: 0.3s;
}

.house-card:hover .roof {
  opacity: 1;
  transform: translateY(0);
  transition-delay: 0.6s;
}

.house-card:hover .chimney {
  opacity: 1;
  transform: translateY(0);
  transition-delay: 0.8s;
}

.house-card:hover .door,
.house-card:hover .window-left,
.house-card:hover .window-right,
.house-card:hover .window-frame {
  opacity: 1;
  transition-delay: 1s;
}

.house-card:hover .doorknob {
  opacity: 1;
  transition-delay: 1.2s;
}

/* Smoke animation */
.house-card:hover .smoke1 {
  opacity: 0.7;
  transition-delay: 1.4s;
  animation: float 3s infinite ease-in-out 1.4s;
}

.house-card:hover .smoke2 {
  opacity: 0.5;
  transition-delay: 1.6s;
  animation: float 3s infinite ease-in-out 1.6s;
}

.house-card:hover .smoke3 {
  opacity: 0.3;
  transition-delay: 1.8s;
  animation: float 3s infinite ease-in-out 1.8s;
}

@keyframes float {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

/* Initial positions for animated elements */
.house-svg .walls {
  transform: translateY(50px);
}

.house-svg .roof {
  transform: translateY(-30px);
}

.house-svg .chimney {
  transform: translateY(-20px);
}

/* Make text centered and clean */
.house-card h4 {
  font-size: 1.4rem;
  margin: 15px 0 0 0;
  text-align: center;
  background: linear-gradient(90deg, #35d977, #7454f5, #b74ee1);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline-block;
}

/* Remove any paragraph in the house card */
.house-card p {
  display: none;
}

/* Additional styles for the alt-placeholder card */
.project-card.alt-placeholder {
  background: linear-gradient(135deg, rgba(116, 84, 245, 0.05) 0%, rgba(183, 78, 225, 0.07) 100%);
  border: none;
  position: relative;
  overflow: hidden;
}

.alt-placeholder::before {
  content: '';
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: linear-gradient(
    45deg,
    transparent 0%,
    rgba(255, 255, 255, 0.03) 50%,
    transparent 100%
  );
  transform: rotate(45deg);
  animation: shimmer 3s infinite linear;
}

.alt-placeholder .project-icon {
  margin-bottom: 20px;
  color: rgba(255, 255, 255, 0.5);
  transform: scale(1);
  transition: all 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.alt-placeholder:hover .project-icon {
  transform: scale(1.2) rotate(180deg);
  color: rgba(255, 255, 255, 0.8);
}

.alt-placeholder h4 {
  margin-bottom: 15px;
  color: rgba(255, 255, 255, 0.7);
  background: linear-gradient(90deg, #35d977, #7454f5);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.alt-placeholder p {
  margin-bottom: 0;
}

@keyframes shimmer {
  0% {
    transform: translateX(-100%) rotate(45deg);
  }
  100% {
    transform: translateX(100%) rotate(45deg);
  }
}

/* Style for the additional "More in the works" message */
.more-works-message {
  text-align: center;
  margin-top: 60px;
  padding: 30px;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 15px;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  transform: translateY(30px);
  opacity: 0;
  animation: fadeUp 0.8s forwards;
  animation-delay: 0.5s;
}

.more-works-message h3 {
  color: #fff;
  margin-bottom: 15px;
  font-size: 1.8rem;
  background: linear-gradient(90deg, #35d977, #7454f5, #b74ee1);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline-block;
}

.more-works-message p {
  color: rgba(255, 255, 255, 0.7);
  font-size: 1.1rem;
  margin-bottom: 10px;
}

@keyframes fadeUp {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (max-width: 768px) {
  .more-works-message {
    padding: 20px;
    margin-top: 40px;
  }
  
  .more-works-message h3 {
    font-size: 1.5rem;
  }
} 